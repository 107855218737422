<template>
  <div>
    <div class="hidden lg:block">
      <SideBar>
        <template v-if="storeData.kyc_type">
          <h1>
            {{
              $t(
                'onboarding.side_panel.' +
                  storeData.kyc_type.toLowerCase() +
                  '.title'
              )
            }}
          </h1>
          <h2>
            {{
              $t(
                'onboarding.side_panel.' +
                  storeData.kyc_type.toLowerCase() +
                  '.subtitle'
              )
            }}
          </h2>
          <div class="indented-custom-bullet mt-5">
            <ul class="w-2/4 mx-auto text-left">
              <li
                v-for="item in $tm(
                  'onboarding.side_panel.' +
                    storeData.kyc_type.toLowerCase() +
                    '.steps'
                )"
                v-bind:key="item"
                v-html="item"
                class="pl-4 mb-4 text-grey-4 text-sm"
              ></li>
            </ul>
          </div>
        </template>
        <template v-else>
          <h1>
            {{ $t('onboarding.side_panel.b2b.title') }}
          </h1>
          <h2>
            {{ $t('onboarding.side_panel.b2b.subtitle') }}
          </h2>
        </template>

        <div>
          <GreenBall
            class="steps__ball w-full"
            start="bottom"
            offset="-8rem"
            cutout="0"
          />

          <transition name="slide">
            <Card
              :firstName="name"
              :lastName="surname"
              class="
                w-4/5
                h-2/4
                max-w-sm max-h-52
                absolute
                bottom-10
                left-6
                scale-125
                rounded-3xl
                transform
              "
            />
          </transition>
        </div>
      </SideBar>
    </div>
    <div class="w-full lg:w-2/3 ml-auto min-h-screen relative">
      <transition name="fade" mode="in-out">
        <div
          class="w-4/5 md:w-3/4 text-left mx-auto pt-6"
          v-if="slideConfig.includes('back')"
        >
          <div
            class="back text-left text-grey-2 flex cursor-pointer items-center"
            @click="previousSlide"
          >
            <img src="@/assets/svg/chev-left.svg" alt="" />

            <span>{{ $t('wording.back') }}</span>
          </div>
        </div>
      </transition>
      <swiper
        class="w-full"
        :loop="false"
        @swiper="setSwiper"
        :slides-per-view="1"
        :space-between="50"
        :pagination="false"
        :allowTouchMove="false"
        :speed="1000"
        :autoplay="false"
        @slideChange="slideChange"
      >
        <!-- <swiper-slide config="later">
          <Four
            class="w-4/5 md:w-3/4 m-auto mt-6 md:mt-12"
            :button="false"
            @next="next"
          />
        </swiper-slide> -->
        <swiper-slide
          config="later|back"
          v-if="storeData.kyc_type === 'IDENTITY_PROOF'"
        >
          <Kyc
            class="w-4/5 md:w-3/4 m-auto mt-6 md:mt-12"
            @nextKyc="kycChosen"
          />
        </swiper-slide>
        <swiper-slide v-if="!isMobile()" config="back|later">
          <KycUploadType
            class="w-4/5 md:w-3/4 m-auto mt-6 md:mt-12"
            @nextKyc="kycUploadTypeChosen"
          />
        </swiper-slide>
        <swiper-slide v-if="kycUploadType === 'mobile'" config="back">
          <KycUploadMobile
            class="w-4/5 md:w-3/4 m-auto mt-6 md:mt-12"
            @sendSMS="sendSMS"
            @canContinue="toggleButton"
          />
        </swiper-slide>
        <swiper-slide config="back|later|next" v-slot="{ isActive }">
          <div>
            <KycUpload
              class="w-4/5 md:w-3/4 m-auto mt-6 md:mt-12"
              :kycType="kycType"
              :phone="phone"
              :kycUploadType="kycUploadType"
              @generatedId="generatedId"
              @canContinue="toggleButton"
              :isActive="isActive"
              :name="name"
              :surname="surname"
            />
            <GeneralInput
                v-model:data="check.incorporation"
                type="checkbox"
                name="incorporation"
                :label="$t('onboarding.kyc.company.incorporation')"
                class="w-4/5 md:w-3/4 m-auto mt-6 md:mt-12"
                no-focus
            />
            <GeneralInput
              v-model:data="check.voting"
              type="checkbox"
              name="voting"
              :label="$t('onboarding.kyc.company.voting')"
              class="w-4/5 md:w-3/4 m-auto mt-6 md:mt-12"
              no-focus
            />
            <GeneralInput
              v-model:data="check.identification"
              type="checkbox"
              name="identification"
              :label="$t('onboarding.kyc.company.identification')"
              class="w-4/5 md:w-3/4 m-auto mt-6 md:mt-12"
              no-focus
            />
            <div
              class="
                w-4/5
                md:w-3/4
                text-right
                left-1/2
                -translate-x-1/2
                transform
                z-50
                absolute
              "
              v-if="slideConfig.includes('next')"
            >
              <GpButton class="ml-auto" 
              :disabled="!valid || !check.identification || !check.voting || !check.incorporation"
              @click="nextSlide">
                {{ $t('wording.submit') }}
              </GpButton>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { ref, defineAsyncComponent, computed } from 'vue'
import { useStore } from 'vuex'

export default {
  components: {
    Swiper,
    SwiperSlide,
    Kyc: defineAsyncComponent(() =>
      import('@/components/onboarding/steps/Kyc.vue')
    ),
    KycUploadType: defineAsyncComponent(() =>
      import('@/components/onboarding/steps/KycUploadType.vue')
    ),
    KycUploadMobile: defineAsyncComponent(() =>
      import('@/components/onboarding/steps/KycUploadMobile.vue')
    ),
    KycUpload: defineAsyncComponent(() =>
      import('@/components/onboarding/steps/KycUpload.vue')
    ),
    GpButton: defineAsyncComponent(() =>
      import('@/components/general/GpButton.vue')
    ),
    GeneralInput: defineAsyncComponent(() =>
      import('@/components/general/Input.vue')
    ),
    SideBar: defineAsyncComponent(() =>
      import('@/components/general/SideBar.vue')
    ),
    GreenBall: defineAsyncComponent(() =>
      import('@/components/general/GreenBall.vue')
    ),
    Card: defineAsyncComponent(() => import('@/components/general/Card.vue')),
    // Four: defineAsyncComponent(() =>
    //   import('@/components/onboarding/b2b/4.vue')
    // )
  },
  props: {
    name: String,
    surname: String
  },
  emits: ['done'],
  setup(props, { emit }) {
    const swiper = ref(null)
    const slideConfig = ref('')
    const kycType = ref(null)
    const kycUploadType = ref('upload')
    const phone = ref(null)
    const smsSent = ref(false)
    const websocketId = ref(null)
    const store = useStore()
    const data = ref(false)
    let check = ref({
      incorporation: false,
      voting: false,
      identification: false
    })

    const valid = computed(() => {
      return (data.value.kycFirst && data.value.kycSecond) ? true : false
    })

    const storeData = computed(() => {
      return store.state.onboarding.data
    })

    // Proceed after choosing type of document
    function next(data) {
      store.dispatch('add', data)
      nextSlide()
    }

    // Submit file to backoffice
    function submitKyc() {
      store.dispatch('submitKyc', data.value)
    }

    // If value (data returned by component), the button should be active, otherwise, there's an error in KycUpload
    function toggleButton(value) {
      data.value = value
    }

    // Set websocketId to ID generated in websocket connection
    function generatedId(value) {
      websocketId.value = value
    }

    // Function to send SMS with link for file upload (Missing twilio sandbox)
    function sendSMS(value) {
      phone.value = value
      smsSent.value = false
    }

    // Set KYC Type (National ID, Passport or Driving license)
    function kycChosen(value) {
      kycType.value = value
      nextSlide()
    }

    // Set KYC Upload Type (Mobile or Desktop)
    function kycUploadTypeChosen(value) {
      kycUploadType.value = value
      nextSlide()
    }

    // Initialize swiper instance (Add swiper component to swiper reference)
    function setSwiper(value) {
      swiper.value = value
    }

    // Go to next slide and submit KYC if case
    function nextSlide() {
      if (data.value.kycFirst && data.value.kycSecond) {
        submitKyc()
        if (
          !store.state.onboarding.kyc.filter((element) => {
            return element.type === storeData.value.kyc_type
          }).length
        ) {
          store.dispatch('doneKyc', {
            kyc_type: storeData.value.kyc_type,
            kyc_state: 'pending'
          })
          data.value = false
        }
          // Finish
          emit('done')
        
      } else {
        swiper.value.slideTo(swiper.value.activeIndex + 1)
      }
    }

    // Go to previous slide
    function previousSlide() {
      swiper.value.slideTo(swiper.value.activeIndex - 1)
    }

    // Triggered when a slide change occurs, get the configs of slide (if has back button, next button, etc...)
    function slideChange() {
      if (swiper.value.activeIndex === 0)
        store.dispatch('add', { kyc_type: null })
      slideConfig.value =
        swiper.value.slides[swiper.value.activeIndex].getAttribute('config') ??
        ''
    }

    // Test if nav browser is mobile or tablet
    function isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true
      } else {
        return false
      }
    }

    return {
      isMobile,
      setSwiper,
      slideChange,
      previousSlide,
      slideConfig,
      kycChosen,
      kycType,
      kycUploadTypeChosen,
      kycUploadType,
      sendSMS,
      toggleButton,
      nextSlide,
      next,
      phone,
      valid,
      generatedId,
      storeData,
      submitKyc,
      data,
      check
    }
  }
}
</script>

<style scoped></style>
